import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="The Advantages of Carbon Fiber | Painted Rhino"
            description="Carbon fiber is a high-strength material that comes with many advantages. It’s strong, lightweight, and versatile, to name a few benefits."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="5 Advantages Of Carbon Fiber Manufacturing"
            date="September 20, 2021"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     Carbon fiber is a high-performance material used for cars, military vehicles, planes, bikes, storage containers, drinkware, and
                     more. It's a high-strength material made of slender, super-tough filaments of carbon. Versatile with advanced tensile properties,
                     carbon fiber has many applications and advantages.
                  </p>

                  <h2>#1 - Lightweight</h2>

                  <p>
                     One of the most significant carbon fiber benefits is that it's a lightweight material. Compared to steel, carbon fiber is five
                     times lighter and twice as light in contrast to aluminum. The lightweight nature of carbon fiber is an advantage for many
                     reasons—the chief of which is lower energy production.{" "}
                  </p>

                  <p>
                     Also, in cars comprised of carbon fiber composites, the weight could be reduced by nearly 50%. This weight reduction could
                     improve fuel efficiency, ultimately saving consumers money by almost 35% without sacrificing safety or performance.
                  </p>

                  <h2>#2 - Strong</h2>

                  <p>
                     Remember how carbon fibers are five times stronger than steel? They're also twice as stiff. The tensile strength, or the ability
                     to be stretched to the point of breaking without doing so, is much greater for carbon fiber materials.
                  </p>

                  <p>
                     Its strength makes carbon fiber ideal for use in manufacturing parts and aircraft materials. The amount of flexibility and
                     strength that carbon fiber offers is a perfect match for military aircraft and commercial airlines.
                  </p>

                  <h2>#3 - Resistant</h2>

                  <p>
                     Carbon fibers, unlike other metals, are resistant to elements and chemical corrosion. They're not susceptible to the same rust or
                     chemical reactions that other metals would be. As a result, oxidization is not an issue for carbon fibers, which is good news for
                     aircraft and other vehicles.
                  </p>

                  <p>
                     The increased use of carbon fibers in vehicles means that only minor bodywork will be needed to repair rust and corrosion from
                     weather or chemicals. Also, no longer will salt be a potential issue for the bottom of cars in snowy areas, nor will ocean mist
                     be a concern on the coast.
                  </p>

                  <h2>#4 - Tolerant</h2>

                  <p>
                     Carbon fibers are tolerant to heat, exhibiting low thermal expansion. Rather than expanding and weakening like other metals,
                     carbon fibers retain their shape and strength when exposed to heat. Carbon fiber also has low thermal conductivity, which means
                     it reduces heat soak in addition to reducing transmission. Metals can transfer or emanate heat causing other parts to be damaged,
                     but not carbon fiber.
                  </p>

                  <p>
                     Carbon fiber is also tolerant to radiofrequency waves. Rather than being receptive to the waves like other metals are, carbon
                     fibers work to block any frequencies that would seek to break your data. Think of carbon fiber as the blocker, whereas other
                     metals act as a receptor.{" "}
                  </p>

                  <h2>#5 - Versatile</h2>

                  <p>
                     Versatility in production is also a prime advantage of carbon fiber. As a result, carbon fiber is found across multiple
                     industries—it's not just for cars and aircraft. In addition, some manufacturers choose to use carbon fiber as their material to
                     showcase their flexibility in application.
                  </p>

                  <h2>Have An Idea?</h2>

                  <p>
                     If you want to know more about how to bring your vision for a carbon fiber prototype to life,{" "}
                     <a data-modal-open="modal-contact">contact Painted Rhino</a> to schedule a consultation today.
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/Carbon-Fiber.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 833) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
